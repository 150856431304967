<template>
	<b-modal id="add-connection" size="lg" title="Add Connection" ref="modal" ok-title="Add" @ok="handleOk"
		@show="onReset" :cancel-disabled="disableConfirmButtons" :ok-disabled="disableConfirmButtons"
		:no-close-on-backdrop="true">
		<loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

		<b-form @submit.stop.prevent="handleSubmit" novalidate>
			<b-container fluid>
				<b-row class="my-2">
					<b-col sm="8">
						<b>SELECT COMPANIES TO CONNECT</b>
					</b-col>
				</b-row>

				<b-row class="mb-1">
					<b-col lg="6" md="6" sm="12" class="my-1">
						<b-form-group label="Main Company">
							<v-select name="Main Company" class="style-chooser" label="text"
								:options="fromCompanyOptions" :reduce="(company) => company.value"
								v-model="selFromCompany" v-validate="'selectRequired'">
								<template v-slot:no-options="{ search, searching }">
									<template v-if="searching">
										No results found for
										<em>
											<strong>{{ search }}</strong>
										</em>
									</template>
									<em :style="{ opacity: 0.5 }" v-else>
										Start typing to search for a company
									</em>
								</template>
							</v-select>
							<span v-show="errors.has('Main Company')" class="help-block">{{
								errors.first('Main Company')
							}}</span>
						</b-form-group>
					</b-col>
					<b-col lg="6" md="6" sm="12" class="my-1">
						<b-form-group label="Other Company">
							<v-select name="Other Company" class="style-chooser" label="text"
								:options="toCompanyOptions" :reduce="(company) => company.value" v-model="selToCompany"
								v-validate="'selectRequired'">
								<template v-slot:no-options="{ search, searching }">
									<template v-if="searching">
										No results found for
										<em>
											<strong>{{ search }}</strong>
										</em>
									</template>
									<em :style="{ opacity: 0.5 }" v-else>
										Start typing to search for a company
									</em>
								</template>
							</v-select>
							<span v-show="errors.has('Other Company')" class="help-block">{{
								errors.first('Other Company')
							}}</span>
						</b-form-group>
					</b-col>
				</b-row>

				<b-row class="mb-1">
					<b-col md="6" class="my-1">
						<b-card :title="selFromCompany.name" v-show="selFromCompany.id != null">
							<p class="card-text">
								<b>Type:</b>
								{{ selFromCompany.type }}
								<br />
								<b>Industry:</b>
								{{ getIndustry(selFromCompany.id) }}
								<br />
								<b>Address:</b>
								{{ getAddress(selFromCompany.id) }}
								<br />

								<span v-if="selFromCompany.isActive === 'true'">
									<b>Status:</b> &nbsp;&nbsp;
									<b-badge variant="success">Active</b-badge>
								</span>
								<span v-else>
									<b>Status:</b> &nbsp;&nbsp;
									<b-badge variant="secondary">Inactive</b-badge>
								</span>
							</p>
						</b-card>
					</b-col>
					<b-col md="6" class="my-1">
						<b-card :title="selToCompany.name" v-show="selToCompany.id != null">
							<p class="card-text">
								<b>Type:</b>
								{{ selToCompany.type }}
								<br />
								<b>Industry:</b>
								{{ getIndustry(selToCompany.id) }}
								<br />
								<b>Address:</b>
								{{ getAddress(selToCompany.id) }}
								<br />
								<span v-if="selToCompany.isActive === 'true'">
									<b>Status:</b> &nbsp;&nbsp;
									<b-badge variant="success">Active</b-badge>
								</span>
								<span v-else>
									<b>Status:</b> &nbsp;&nbsp;
									<b-badge variant="secondary">Inactive</b-badge>
								</span>
							</p>
						</b-card>
					</b-col>
				</b-row>

				<hr />

				<b-row class="my-2">
					<b-col sm="4">
						<b>CONNECTION TYPES</b>
					</b-col>
				</b-row>
				<b-row class="mb-1">
					<b-col lg="6" md="6" sm="12">
						<b-form-group label="Select one or more connection types">
							<b-form-checkbox-group stacked id="connectionTypes" name="Connection Types"
								v-model="form.connections">
								<b-form-checkbox v-bind:key="conType.id" :value="conType.value"
									v-for="conType in connectionTypes">{{ conType.text }}</b-form-checkbox>
							</b-form-checkbox-group>
						</b-form-group>
						<b-form-group v-show="showOthersField" label="If Others, please specify" label-for="others"
							description>
							<b-form-input name="Others" v-model="form.others" type="text" v-validate="{
								required: showOthersField,
							}" placeholder="Please specify"></b-form-input>
							<span v-show="errors.has('Others')" class="help-block">{{
								errors.first('Others')
							}}</span>
						</b-form-group>
					</b-col>
					<b-col lg="6" md="6" sm="12">
						<b-form-group label="Notes:" label-for="notes" description>
							<b-form-textarea id="notes" name="Notes" v-model="form.notes" placeholder="Other info here"
								:rows="2" :max-rows="6"></b-form-textarea>
							<span v-show="errors.has('Notes')" class="help-block">{{
								errors.first('Notes')
							}}</span>
						</b-form-group>
					</b-col>
				</b-row>

				<hr />

				<b-row class="my-2">
					<b-col sm="8">
						<b>OTHER COMPANY STORAGE LOCATION</b>
					</b-col>
				</b-row>
				<b-row class="mb-1">
					<b-col sm="12" class="my-1">
						<b-table show-empty striped hover :items="storageLocationItems" :fields="fields">
							<template v-slot:cell(selection)="row">
								<b-form-checkbox :id="row.item.id"
									v-model="storageLocationsObj[row.item.id]['isIncluded']" value="true"
									unchecked-value="false" />
							</template>
							<template v-slot:cell(geoaddress)="row">({{ row.item.geoaddress.latitude }},
								{{ row.item.geoaddress.longitude }})</template>
						</b-table>
					</b-col>
				</b-row>
			</b-container>
		</b-form>
	</b-modal>
</template>

<script>
// Util
import { ValidationUtil } from '@/utils/validationUtil';
import { DropDownItemsUtil } from '@/utils/dropDownItemsUtil';
import { LocationUtil } from '@/utils/locationUtil';

// API & Database
import connectionsDAO from '@/database/connections';
import storageLocationApi from '@/api/storageLocationApi';

// Others
import EventBus from '@/shared/event-bus';
import config from '@/config/env-constants';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import _ from 'lodash';

export default {
	name: 'add-connection',
	components: {
		Loading,
	},
	props: {
		allCompaniesObj: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			fields: [
				{
					key: 'selection',
					label: '#',
					sortable: false,
				},
				{
					key: 'name',
					sortable: true,
				},
				{
					key: 'address',
					sortable: false,
				},
				'geoaddress',
			],
			form: {
				company: {},
				companyId: '',
				connectedCompany: {},
				connectedCompanyId: '',
				connections: [],
				storageLocations: {},
				isActive: 'true',
				notes: '',
				others: '',
			},
			selFromCompany: config.companyDefaultValue,
			selToCompany: config.companyDefaultValue,
			fromCompanyOptions: {},
			toCompanyOptions: {},
			connectionTypes: [],
			storageLocationItems: [],
			storageLocationsObj: {},
			showOthersField: false,

			isSuperAdmin: this.$store.getters.isSuperAdmin,
			loggedUser: this.$store.getters.loggedUser,
			loggedUserCompany: this.$store.getters.loggedUserCompany,
			// Check for loader
			isLoading: false,
		};
	},
	watch: {
		allCompaniesObj: function () {
			// From Companies
			if (this.isSuperAdmin) {
				this.fromCompanyOptions = DropDownItemsUtil.retrieveActiveCompanyItems(
					this.allCompaniesObj
				);
			} else {
				let filteredCompaniesObj = _.omitBy(
					this.allCompaniesObj,
					(o) => o.parentCompanyId !== this.loggedUserCompany.id
				);
				filteredCompaniesObj[this.loggedUserCompany.id] =
					this.loggedUserCompany;

				this.fromCompanyOptions =
					DropDownItemsUtil.retrieveActiveCompanyItems(filteredCompaniesObj);
			}

			// To Companies
			this.toCompanyOptions = DropDownItemsUtil.retrieveCompanyItems(
				this.allCompaniesObj, this.loggedUserCompany
			);
		},
		'form.connections': function () {
			if (this.form.connections && this.form.connections.includes('Others')) {
				this.showOthersField = true;
			} else {
				this.showOthersField = false;
			}
		},
		selToCompany: async function (newVal, _oldVal) {
			if (newVal && newVal.id) {
				await this.retrieveStorageLocations(newVal);
			}
		},
	},
	computed: {
		disableConfirmButtons() {
			return this.isLoading;
		},
	},
	mounted() {
		this.retrieveConnectionTypes();
	},
	methods: {
		retrieveConnectionTypes() {
			let vm = this;
			this.$store.dispatch('getAllConnectionTypes').then((results) => {
				Object.keys(results).forEach((connectionType) => {
					vm.connectionTypes.push({
						text: connectionType,
						value: connectionType,
					});
				});
				vm.connectionTypes = _.sortBy(vm.connectionTypes, 'text');
				vm.connectionTypes.push({
					text: "Others",
					value: "Others"
				});
			});
		},
		async handleOk(evt) {
			// Prevent modal from closing
			evt.preventDefault();

			// show loading indicator
			this.isLoading = true;

			let isValid = await this.$validator.validateAll();
			if (!isValid) {
				this.$toaster.warning('Please address the field/s with invalid input.');
				// hide loading indicator
				this.isLoading = false;
				return;
			}

			let existsConnection = await this.hasExistingConnection();
			if (existsConnection) {
				this.$toaster.warning(
					'There is already an existing connection between this two companies.'
				);
				// hide loading indicator
				this.isLoading = false;
				return;
			}

			// Company and Other Company must not be the same
			if (this.selFromCompany.id === this.selToCompany.id) {
				this.$toaster.warning('Company and Other Company must be different.');
				// hide loading indicator
				this.isLoading = false;
				return;
			}
			// At least 1 connection required
			else if (this.form.connections.length <= 0) {
				this.$toaster.warning(
					'You should have at least 1 connection type between the selected companies.'
				);
				// hide loading indicator
				this.isLoading = false;
				return;
			}
			// if Others is selected, the others field is required
			else if (
				this.form.connections.includes('Others') &&
				this.form.others.length <= 0
			) {
				this.$toaster.warning('Please fill-in the Others field to proceed.');
				// hide loading indicator
				this.isLoading = false;
				return;
			}
			// At least 1 storage location must be connected
			else if (!this.hasAtLeastOneStorageLocation()) {
				this.$toaster.warning(
					'Please select at least 1 storage location to connect.'
				);
				// hide loading indicator
				this.isLoading = false;
				return;
			}

			this.handleSubmit();
		},
		hasAtLeastOneStorageLocation() {
			let hasStorageLocation = false;
			let locArr = Object.values(this.storageLocationsObj);
			for (const loc of locArr) {
				if (loc.isIncluded === 'true') {
					hasStorageLocation = true;
					break;
				}
			}
			return hasStorageLocation;
		},
		async hasExistingConnection() {
			let status = false;
			if (this.selFromCompany.id && this.selToCompany.id) {
				let connectionObj = await connectionsDAO.getConnection(
					this.selFromCompany.id,
					this.selToCompany.id
				);

				return !_.isEmpty(connectionObj);
			}
			return status;
		},
		getParam() {
			// Removes excess whitespace
			this.form.notes = ValidationUtil.removeExcessWhiteSpace(this.form.notes);
			this.form.others = ValidationUtil.removeExcessWhiteSpace(
				this.form.others
			);

			// update selection
			this.updateFromCompany();
			this.updateToCompany();
			this.updateStorageLocations();

			return this.form;
		},
		async handleSubmit() {
			// show loading indicator
			this.isLoading = true;

			try {
				const result = await this.$store.dispatch(
					'addConnection',
					this.getParam()
				);

				// hide loading indicator
				this.isLoading = false;

				if (result.isSuccess) {
					// Update company setup status
					const companySetupParam = {
						companyId: this.selFromCompany.id,
						data: {
							hasConnection: true,
						},
					};
					await this.$store.dispatch(
						'updateCompanySetupStatus',
						companySetupParam
					);

					this.$toaster.success(
						`New Connection between ${this.selFromCompany.name} and ${this.selToCompany.name} was created successfully.`
					);
					EventBus.$emit('onCloseAddConnection', result.data);
					this.$refs.modal.hide();
				} else {
					this.$toaster.error(
						`Error creating connection between ${this.selFromCompany.name} and ${this.selToCompany.name}. Please try again.`
					);
				}
			} catch (error) {
				// hide loading indicator
				this.isLoading = false;

				this.$toaster.error(
					`Error creating connection between ${this.selFromCompany.name} and ${this.selToCompany.name}. Please try again.`
				);
			}
		},
		updateFromCompany() {
			this.form.company = this.getCompany(this.selFromCompany.id);
			this.form.company.geoaddress = LocationUtil.getGeoaddress(this.form.company.geoaddress);
			this.form.companyId = this.selFromCompany.id;
		},
		updateToCompany() {
			this.form.connectedCompany = this.getCompany(this.selToCompany.id);
			this.form.connectedCompany.geoaddress = LocationUtil.getGeoaddress(this.form.connectedCompany.geoaddress);
			this.form.connectedCompanyId = this.selToCompany.id;
		},
		updateStorageLocations() {
			this.form.storageLocations = this.storageLocationsObj;
		},
		onReset() {
			/* Reset our form values */
			this.form.company = {};
			this.form.companyId = '';
			this.form.connectedCompany = {};
			this.form.connectedCompanyId = '';
			this.form.connections = [];
			this.form.storageLocations = {};
			this.form.isActive = 'true';
			this.form.notes = '';
			this.form.others = '';

			this.selFromCompany = config.companyDefaultValue;
			this.selToCompany = config.companyDefaultValue;

			this.storageLocationItems = [];
			this.storageLocationsObj = {};

			// reset validation
			this.$validator.reset();
			this.errors.clear();
		},
		async retrieveStorageLocations(selCompany) {
			// show loading indicator
			this.isLoading = true;

			// reset values
			this.storageLocationItems = [];
			this.storageLocationsObj = {};

			let filter = {
				isActive: 'true',
				company: { id: selCompany.id },
				companyId: selCompany.id,
				includeConnection: false,
			};
			let { data } = await storageLocationApi.getStorageLocations(
				filter,
				config.view.COMPANY,
				this.loggedUser.id
			);
			_.forEach(data.storageLocations, (value, key) => {
				let loc = value;
				this.storageLocationsObj[loc.id] = {
					id: loc.id,
					name: loc.name,
					address: loc.address,
					geoaddress: LocationUtil.getGeoaddress(loc.geoaddress),
					isIncluded: 'false',
					isActive: loc.isActive,
				};

				this.storageLocationItems.push(this.storageLocationsObj[key]);
			});

			// hide loading indicator
			this.isLoading = false;
		},
		getCompany(id) {
			if (this.allCompaniesObj[id]) {
				return this.allCompaniesObj[id];
			}
			return config.companyDefaultValue;
		},
		getIndustry(id) {
			if (this.allCompaniesObj[id]) {
				return this.allCompaniesObj[id].industry;
			}
			return '';
		},
		getAddress(id) {
			if (this.allCompaniesObj[id]) {
				return this.allCompaniesObj[id].address;
			}
			return '';
		},
	},
};
</script>
