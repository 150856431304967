import { db } from '@/config/firebase';

async function getConnection(companyId, connectedCompanyId) {
    let connectionObj = {};
    const query = db.collection('connections')
        .where("company.id", "==", companyId)
        .where("connectedCompany.id", "==", connectedCompanyId)
        .limit(1);
    const querySnapshot = await query.get();

    querySnapshot.forEach((doc) => {
        connectionObj = doc.data();
    });

    return connectionObj;
}

export default {
    getConnection
}