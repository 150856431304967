<template>
	<b-modal id="edit-connection" size="lg" title="Edit Connection" ref="modal" ok-title="Update" @ok="handleOk"
		@show="onReset" :cancel-disabled="disableConfirmButtons" :ok-disabled="disableConfirmButtons"
		:no-close-on-backdrop="true">
		<loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

		<b-form @submit.stop.prevent="handleSubmit" novalidate>
			<b-container fluid>
				<b-row class="my-2">
					<b-col sm="8">
						<b>SELECT COMPANIES TO CONNECT</b>
					</b-col>
				</b-row>

				<b-row class="mb-1">
					<b-col lg="6" md="6" sm="12" class="my-1">
						<b-form-group label="Main Company:" label-for="fromCompany" description>
							<b-form-input name="Name" type="text" v-model="form.company.name" readonly />
						</b-form-group>
					</b-col>
					<b-col lg="6" md="6" sm="12" class="my-1">
						<b-form-group label="Other Company:" label-for="toCompany" description>
							<b-form-input name="Name" type="text" v-model="form.connectedCompany.name"
								@change="retrieveStorageLocations" readonly />
						</b-form-group>
					</b-col>
				</b-row>

				<b-row class="mb-1">
					<b-col lg="6" md="6" sm="12" class="my-1">
						<b-card :title="selFromCompany.name" v-show="selFromCompany.id != null">
							<p class="card-text">
								<b>Type:</b>
								{{ selFromCompany.type }}
								<br />
								<b>Industry:</b>
								{{ selFromCompany.industry }}
								<br />
								<b>Address:</b>
								{{ selFromCompany.address }}
								<br />

								<span v-if="selFromCompany.isActive === 'true'">
									<b>Status:</b> &nbsp;&nbsp;
									<b-badge variant="success">Active</b-badge>
								</span>
								<span v-else>
									<b>Status:</b> &nbsp;&nbsp;
									<b-badge variant="secondary">Inactive</b-badge>
								</span>
							</p>
						</b-card>
					</b-col>
					<b-col lg="6" md="6" sm="12" class="my-1">
						<b-card :title="selToCompany.name" v-show="selToCompany.id != null">
							<p class="card-text">
								<b>Type:</b>
								{{ selToCompany.type }}
								<br />
								<b>Industry:</b>
								{{ selToCompany.industry }}
								<br />
								<b>Address:</b>
								{{ selToCompany.address }}
								<br />
								<span v-if="selToCompany.isActive === 'true'">
									<b>Status:</b> &nbsp;&nbsp;
									<b-badge variant="success">Active</b-badge>
								</span>
								<span v-else>
									<b>Status:</b> &nbsp;&nbsp;
									<b-badge variant="secondary">Inactive</b-badge>
								</span>
							</p>
						</b-card>
					</b-col>
				</b-row>

				<hr />

				<b-row class="my-2">
					<b-col sm="4">
						<b>CONNECTION TYPES</b>
					</b-col>
				</b-row>
				<b-row class="mb-1">
					<b-col lg="6" md="6" sm="12">
						<b-form-group label="Select one or more connection types">
							<b-form-checkbox-group stacked id="connectionTypes" name="Connection Types"
								v-model="form.connections">
								<b-form-checkbox v-bind:key="conType.id" :value="conType.value"
									v-for="conType in connectionTypes">
									{{ conType.text }}
								</b-form-checkbox>
							</b-form-checkbox-group>
						</b-form-group>
						<b-form-group v-show="showOthersField" label="If Others, please specify" label-for="others"
							description>
							<b-form-input name="Others" v-model="form.others" type="text" v-validate="{
								required: showOthersField,
							}" placeholder="Please specify" />
							<span v-show="errors.has('Others')" class="help-block">{{
								errors.first('Others')
							}}</span>
						</b-form-group>
					</b-col>
					<b-col lg="6" md="6" sm="12">
						<b-form-group label="Notes:" label-for="notes" description>
							<b-form-textarea id="notes" name="Notes" v-model="form.notes" placeholder="Other info here"
								:rows="2" :max-rows="6"></b-form-textarea>
							<span v-show="errors.has('Notes')" class="help-block">{{
								errors.first('Notes')
							}}</span>
						</b-form-group>
					</b-col>
				</b-row>

				<hr />

				<b-row class="my-2">
					<b-col sm="8">
						<b>OTHER COMPANY STORAGE LOCATION</b>
					</b-col>
				</b-row>
				<b-row class="mb-1">
					<b-col sm="12" class="my-1">
						<b-table show-empty striped hover :items="storageLocationOptions" :fields="fields">
							<template v-slot:cell(selection)="row">
								<b-form-checkbox :id="row.item.id"
									v-model="storageLocationsObj[row.item.id]['isIncluded']" value="true"
									unchecked-value="false"></b-form-checkbox>
							</template>
							<template v-slot:cell(geoaddress)="row">({{ row.item.geoaddress.latitude }},
								{{ row.item.geoaddress.longitude }})</template>
						</b-table>
					</b-col>
				</b-row>
			</b-container>
		</b-form>
	</b-modal>
</template>

<script>
// Util
import { ValidationUtil } from '@/utils/validationUtil';
import { LocationUtil } from '@/utils/locationUtil';

// API & Database
import connectionsDAO from '@/database/connections';
import storageLocationApi from '@/api/storageLocationApi';

// Others
import EventBus from '@/shared/event-bus';
import config from '@/config/env-constants';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import _ from 'lodash';

export default {
	name: 'edit-connection',
	components: {
		Loading,
	},
	data() {
		return {
			fields: [
				{
					key: 'selection',
					label: '#',
					sortable: false,
				},
				{
					key: 'name',
					sortable: true,
				},
				{
					key: 'address',
					sortable: false,
				},
				'geoaddress',
			],
			form: {
				company: {},
				companyId: '',
				connectedCompany: {},
				connectedCompanyId: '',
				connections: [],
				storageLocations: {},
				isActive: 'true',
				notes: '',
				others: '',
				dateCreated: '',
				createdBy: '',
				dateUpdated: '',
				updatedBy: '',
			},
			selFromCompany: config.companyDefaultValue,
			selToCompany: config.companyDefaultValue,
			connectionTypes: [],
			storageLocationOptions: [],
			storageLocationsObj: {},
			showOthersField: false,

			selConnection: {},

			isSuperAdmin: this.$store.getters.isSuperAdmin,
			loggedUser: this.$store.getters.loggedUser,
			loggedUserCompany: this.$store.getters.loggedUserCompany,
			// Check for loader
			isLoading: false,
		};
	},
	watch: {
		'form.connections': function () {
			if (this.form.connections && this.form.connections.includes('Others')) {
				this.showOthersField = true;
			} else {
				this.showOthersField = false;
			}
		},
	},
	computed: {
		disableConfirmButtons() {
			return this.isLoading;
		},
	},
	mounted() {
		this.retrieveConnectionTypes();

		EventBus.$on('onUpdateSelConnection', (selConnection) => {
			this.selConnection = selConnection;
		});
	},
	methods: {
		retrieveConnectionTypes() {
			let vm = this;
			this.$store.dispatch('getAllConnectionTypes').then((results) => {
				Object.keys(results).forEach((connectionType) => {
					vm.connectionTypes.push({
						text: connectionType,
						value: connectionType,
					});
				});
				vm.connectionTypes = _.sortBy(vm.connectionTypes, 'text');
				vm.connectionTypes.push({
					text: "Others",
					value: "Others"
				});
			});
		},
		async handleOk(evt) {
			// Prevent modal from closing
			evt.preventDefault();

			// show loading indicator
			this.isLoading = true;

			let isValid = await this.$validator.validateAll();
			if (!isValid) {
				this.$toaster.warning('Please address the field/s with invalid input.');
				// hide loading indicator
				this.isLoading = false;
				return;
			}

			let existsConnection = await this.hasExistingConnection();
			if (existsConnection && !this.isOrigConnectionLoaded()) {
				this.$toaster.warning(
					'There is already an existing connection between this two companies.'
				);
				// hide loading indicator
				this.isLoading = false;
				return;
			}

			// Company and Other Company are required
			if (this.selFromCompany.id === null || this.selToCompany.id === null) {
				this.$toaster.warning('Company and Other Company is required.');
				// hide loading indicator
				this.isLoading = false;
				return;
			}
			// Company and Other Company must not be the same
			else if (this.selFromCompany.id === this.selToCompany.id) {
				this.$toaster.warning('Company and Other Company must be different.');
				// hide loading indicator
				this.isLoading = false;
				return;
			}
			// At least 1 connection required
			else if (this.form.connections.length <= 0) {
				this.$toaster.warning(
					'You should have at least 1 connection type between the selected companies.'
				);
				// hide loading indicator
				this.isLoading = false;
				return;
			}
			// if Others is selected, the others field is required
			else if (
				this.form.connections.includes('Others') &&
				this.form.others.length <= 0
			) {
				this.$toaster.warning(
					'Please fill-in the Others field to proceed.',
					'Warning'
				);
				// hide loading indicator
				this.isLoading = false;
				return;
			}
			// At least 1 storage location must be connected
			else if (!this.hasAtLeastOneStorageLocation()) {
				this.$toaster.warning(
					'Please select at least 1 storage location to connect.',
					'Warning'
				);
				// hide loading indicator
				this.isLoading = false;
				return;
			}

			this.handleSubmit();
		},
		hasAtLeastOneStorageLocation() {
			let hasStorageLocation = false;
			let locArr = Object.values(this.storageLocationsObj);
			for (const loc of locArr) {
				if (loc.isIncluded === 'true') {
					hasStorageLocation = true;
					break;
				}
			}
			return hasStorageLocation;
		},
		async hasExistingConnection() {
			let status = false;
			if (this.selFromCompany.id && this.selToCompany.id) {
				let connectionObj = await connectionsDAO.getConnection(
					this.selFromCompany.id,
					this.selToCompany.id
				);

				return !_.isEmpty(connectionObj);
			}
			return status;
		},
		isOrigConnectionLoaded() {
			let status = false;
			if (
				this.selFromCompany.name === this.selConnection.company.name &&
				this.selToCompany.name === this.selConnection.connectedCompany.name
			) {
				status = true;
			}
			return status;
		},
		getParam() {
			// Removes excess whitespace
			this.form.notes = ValidationUtil.removeExcessWhiteSpace(this.form.notes);
			this.form.others = ValidationUtil.removeExcessWhiteSpace(
				this.form.others
			);

			// update selection
			this.updateFromCompany();
			this.updateToCompany();
			this.updateStorageLocations();

			return this.form;
		},
		async handleSubmit() {
			// show loading indicator
			this.isLoading = true;

			try {
				let result = await this.$store.dispatch(
					'updateConnection',
					this.getParam()
				);

				// hide loading indicator
				this.isLoading = false;

				if (result.isSuccess) {
					this.$toaster.success(
						`Connection between "${this.selFromCompany.name}" and "${this.selToCompany.name}" was updated successfully.`
					);
					EventBus.$emit('onCloseEditConnection', result.data);
					this.$refs.modal.hide();
				} else {
					this.$toaster.error(
						`Error updating connection between "${this.selFromCompany.name}" and "${this.selToCompany.name}". Please try again.`
					);
				}
			} catch (error) {
				// hide loading indicator
				this.isLoading = false;

				this.$toaster.error(
					`Error updating connection between "${this.selFromCompany.name}" and "${this.selToCompany.name}". Please try again.`
				);
			}
		},
		updateFromCompany() {
			this.form.company = this.selFromCompany;
			this.form.company.geoaddress = LocationUtil.getGeoaddress(this.form.company.geoaddress);
			this.form.companyId = this.selFromCompany.id;
		},
		updateToCompany() {
			this.form.connectedCompany = this.selToCompany;
			this.form.connectedCompany.geoaddress = LocationUtil.getGeoaddress(this.form.connectedCompany.geoaddress);
			this.form.connectedCompanyId = this.selToCompany.id;
		},
		updateStorageLocations() {
			this.form.storageLocations = this.storageLocationsObj;
		},
		async onReset() {
			// init form
			let connection = this.selConnection;
			let connectionJSON = JSON.stringify(connection);
			this.form = JSON.parse(connectionJSON);

			this.selFromCompany = connection.company;
			this.selToCompany = connection.connectedCompany;
			await this.retrieveStorageLocations(this.selToCompany);

			// reset validation
			this.$validator.reset();
			this.errors.clear();
		},
		async retrieveStorageLocations(selCompany) {
			// reset values
			this.storageLocationOptions = [];
			this.storageLocationsObj = {};

			let filter = {
				isActive: 'true',
				company: { id: selCompany.id },
				companyId: selCompany.id,
				includeConnection: false,
			};
			let { data } = await storageLocationApi.getStorageLocations(
				filter,
				config.view.COMPANY,
				this.loggedUser.id
			);
			_.forEach(data.storageLocations, (value, key) => {
				let loc = value;
				this.storageLocationsObj[loc.id] = {
					id: loc.id,
					name: loc.name,
					address: loc.address,
					geoaddress: LocationUtil.getGeoaddress(loc.geoaddress),
					isIncluded: 'false',
					isActive: loc.isActive,
				};

				this.storageLocationOptions.push(this.storageLocationsObj[key]);
			});

			// update the isIncluded status on each storage locations
			_.forEach(this.storageLocationOptions, (loc) => {
				let selConLoc = this.selConnection.storageLocations[loc.id];
				if (selConLoc) {
					loc.isIncluded = selConLoc.isIncluded;
					this.storageLocationsObj[loc.id].isIncluded = selConLoc.isIncluded;
				}
			});
		},
	},
	beforeDestroy() {
		EventBus.$off('onUpdateSelConnection');
	},
};
</script>