<template>
	<div class="animated fadeIn">
		<b-card title="Connection" sub-title="Manages the connection betweeen companies within the supply chain">
			<b-container class="mt-4">
				<loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

				<!-- Filter  -->
				<b-row class="mt-2">
					<b-col sm="12" md="3" lg="3">
						<b-button v-b-popover.hover.right="'Toggle to show/hide filter options'" v-b-toggle.collapse-1
							class="filter">
							FILTER OPTIONS
						</b-button>
					</b-col>
					<b-col sm="12">
						<!-- Collapsible Filter Options -->
						<b-collapse id="collapse-1" class="mt-2">
							<b-card>
								<b-row no-gutters>
									<b-col lg="4" md="6" sm="12" class="mr-2">
										<b-form-group label="Company">
											<v-select class="style-chooser" label="text" :options="companyOptions"
												:reduce="(company) => company.value" v-model="filterBy.company">
												<template v-slot:no-options="{ search, searching }">
													<template v-if="searching">
														No results found for
														<em>
															<strong>{{ search }}</strong>
														</em>
													</template>
													<em :style="{ opacity: 0.5 }" v-else>
														Start typing to search for a company
													</em>
												</template>
											</v-select>
										</b-form-group>
									</b-col>
									<b-col lg="4" md="6" sm="12" class="mr-2">
										<b-form-group label="Status">
											<v-select class="style-chooser" label="text" :options="statusOptions"
												:reduce="(isActive) => isActive.value" v-model="filterBy.isActive">
												<template v-slot:no-options="{ search, searching }">
													<template v-if="searching">
														No results found for
														<em>
															<strong>{{ search }}</strong>
														</em>
													</template>
													<em :style="{ opacity: 0.5 }" v-else>
														Start typing to search for status
													</em>
												</template>
											</v-select>
										</b-form-group>
									</b-col>
								</b-row>
								<b-row no-gutters>
									<b-col sm="12">
										<b-button class="mr-1" variant="success" @click="onFilterRequest">
											Search
										</b-button>
										<b-button class="mr-1" variant="primary" @click="resetFilters">
											Reset
										</b-button>
									</b-col>
								</b-row>
							</b-card>
						</b-collapse>
					</b-col>
				</b-row>

				<!-- Select Actions and Items Per Page Options -->
				<b-row>
					<b-col sm="6" md="3" class="mt-4 mb-2">
						<b-dropdown text=" Select Actions " variant="dark" slot="append">
							<b-dropdown-item v-b-modal.add-connection v-show="!isViewer">
								Add Connection
							</b-dropdown-item>
							<b-dropdown-item>
								<json-excel :data="exportData" :fields="exportFields" type="xls" :name="fileName + '.xls'">
									Export Connections in Excel
								</json-excel>
							</b-dropdown-item>
							<b-dropdown-item>
								<json-excel :data="exportData" :fields="exportFields" type="csv" :name="fileName + '.csv'">
									Export Connections to CSV
								</json-excel>
							</b-dropdown-item>
						</b-dropdown>
					</b-col>
					<b-col sm="6" md="4" offset-md="5" class="mt-4 mb-2 text-md-right">
						<b-input-group prepend="Show" append="/ Page">
							<b-form-select :options="pageOptions" v-model="perPage" />
						</b-input-group>
					</b-col>
				</b-row>

				<b-table show-empty striped hover :items="items" :fields="fields" :current-page="currentPage"
					:per-page="perPage" :filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
					:sort-direction="sortDirection" responsive>
					<template v-slot:cell(connections)="row">
						<span :key="connection" v-for="connection in row.item.connections">
							<span v-if="connection !== 'Others'">{{ connection }}</span>
							<span v-else>{{ connection }} - <i>{{ row.item.others }}</i></span>
							<br />
						</span>
					</template>

					<template v-slot:cell(noOfStorageLocations)="row">
						<span class="text-center">{{
							getIncludedStorageLocations(row.item.storageLocations)
						}}</span>
					</template>

					<template v-slot:cell(actions)="row">
						<span class="text-nowrap">
							<b-button size="sm" v-b-tooltip.hover.top="'Show/Hide Other Details'" variant="success"
								@click.stop="row.toggleDetails" class="mr-1">
								<i class="fa fa-eye-slash" v-if="row.detailsShowing"></i>
								<i class="fa fa-eye" v-else></i>
							</b-button>
							<b-button size="sm" v-b-modal.edit-connection v-b-tooltip.hover.top="'Edit Details'"
								variant="dark" @click.stop="
									updateSelConnection(row.item, row.index, $event.target)
								" class="mr-1" v-show="!isViewer">
								<i class="fa fa-pencil"></i>
							</b-button>
						</span>
					</template>

					<template v-slot:cell(status)="row">
						<a @click="updateSelConnection(row.item)" v-if="!isViewer">
							<b-form-checkbox name="status" v-b-modal.activate-connection v-model="row.item.isActive" switch
								size="sm">
								{{ row.item.isActive === 'true' ? 'Active' : 'Inactive' }}
							</b-form-checkbox>
						</a>
						<span v-else>{{
							row.item.isActive === 'true' ? 'Active' : 'Inactive'
						}}</span>
					</template>

					<template slot="row-details" slot-scope="row">
						<ConnectionDetailsView :row="row" />
					</template>
				</b-table>

				<b-row>
					<b-col md="8" sm="12" class="my-1">
						<span class="totalDisplay">Total: {{ totalRows }}</span>
					</b-col>
					<b-col md="4" sm="12" class="my-1">
						<b-pagination align="right" :total-rows="totalRows" :per-page="perPage" v-model="currentPage"
							class="my-0" />
					</b-col>
				</b-row>
			</b-container>
		</b-card>

		<!-- Modals here -->
		<AddConnection :allCompaniesObj="allCompaniesObj" />
		<EditConnection />
		<ActivateConnection />
	</div>
</template>

<script>
// Components
import AddConnection from '@/views/setup/connection/AddConnection';
import EditConnection from '@/views/setup/connection/EditConnection';
import ActivateConnection from '@/views/setup/connection/ActivateConnection';
import ConnectionDetailsView from '@/views/setup/connection/ConnectionDetailsView';

// Util
import { DateUtil } from '@/utils/dateutil';
import { DropDownItemsUtil } from '@/utils/dropDownItemsUtil';
import { UserUtil } from '@/utils/userutil';

// API
import connectionApi from '@/api/connectionApi';

// Others
import config from '@/config/env-constants';
import EventBus from '@/shared/event-bus';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import JsonExcel from 'vue-json-excel';
import _ from 'lodash';

export default {
	name: 'connection',
	components: {
		AddConnection,
		EditConnection,
		ActivateConnection,
		ConnectionDetailsView,
		Loading,
		JsonExcel,
	},
	data() {
		return {
			items: [],
			fields: [
				{
					key: 'companyName',
					label: 'Company',
					sortable: true,
				},
				{
					key: 'connectedCompanyName',
					label: 'Connected Company',
					sortable: true,
				},
				'connections',
				'noOfStorageLocations',
				'actions',
				'status',
			],
			currentPage: 1,
			perPage: 10,
			totalRows: 0,
			pageOptions: [5, 10, 15, 25, 50, 100],
			sortBy: null,
			sortDesc: false,
			sortDirection: 'asc',
			filter: null,

			defaultFilterBy: {
				company: Object.assign({}, config.companyDefaultValue),
				isActive: 'true',
			},
			filterBy: {
				company: Object.assign({}, config.companyDefaultValue),
				isActive: 'true',
			},
			prevFilter: {},

			companyOptions: [],
			statusOptions: config.statusOptionsWithDefault,

			allCompaniesObj: {},
			allConnectionsObj: {},
			selConnection: {},

			isSuperAdmin: this.$store.getters.isSuperAdmin,
			isViewer: this.$store.getters.isViewer,
			loggedUser: this.$store.getters.loggedUser,
			loggedUserCompany: this.$store.getters.loggedUserCompany,
			// Check for loader
			isLoading: false,
		};
	},
	computed: {
		/**
		 * Returns the set of data to be included in the export. For now this just
		 * returns the data as is.
		 *
		 * @returns {Array} the set of data to be included in the export.
		 */
		exportData() {
			return this.items;
		},

		/**
		 * Derives the field information based from the data table configuration.
		 *
		 * @returns {object} the fields to be included in the export.
		 */
		exportFields() {
			return {
				From: 'company.name',
				To: 'connectedCompany.name',
				Connections: 'connections',
				Notes: 'notes',
				Status: 'status',
				'No of Storage Locations': 'No of Storage Locations',
				'Storage Locations': 'Storage Locations',
				'Date Created': 'Date Created',
				'Date Updated': 'Date Updated',
			};
		},

		fileName() {
			let currTimeStamp = DateUtil.getCurrentTimestamp();
			return (
				'Connection-' + DateUtil.getDateInDDMMYYYYHHSSFormat(currTimeStamp)
			);
		},
	},
	mounted() {
		setTimeout(() => {
			try {
				// Don't initiate data retrieval when the account is not authenticated
				if (!this.$store.getters.isAuthenticated) {
					return;
				}

				// show loading indicator
				this.isLoading = true;

				this.allCompaniesObj = this.$store.getters.companies;
				this.companyOptions = DropDownItemsUtil.retrieveCompanyItems(
					this.allCompaniesObj, this.loggedUserCompany
				);

				if (!this.isSuperAdmin) {
					this.filterBy.company = DropDownItemsUtil.getCompanyItem(
						this.loggedUserCompany
					);
					this.defaultFilterBy.company = DropDownItemsUtil.getCompanyItem(
						this.loggedUserCompany
					);
				}

				this.allConnectionsObj = this.$store.getters.connections;
				this.filterTableContent();
			} catch (error) {
				this.$toaster.error(
					'Error loading data. Please reload the page again.'
				);
			}

			// hide loading indicator
			this.isLoading = false;
		}, config.timeout);

		// Event Listeners
		EventBus.$on('onCloseAddConnection', (connectionObj) => {
			this.updateTable(connectionObj);
		});
		EventBus.$on('onCloseEditConnection', (connectionObj) => {
			this.updateTable(connectionObj);
		});
		EventBus.$on('onCloseActivateConnection', (connectionObj) => {
			this.updateTable(connectionObj);
		});
	},
	methods: {
		updateTable(connectionObj) {
			if (!_.isEmpty(connectionObj)) {
				// add fields for display
				connectionObj['companyName'] = connectionObj.company.name;
				connectionObj['connectedCompanyName'] =
					connectionObj.connectedCompany.name;

				this.allConnectionsObj[connectionObj.id] = connectionObj;
				this.$store.dispatch('updateAllConnections', this.allConnectionsObj);

				this.filterTableContent();
			}
		},
		filterTableContent() {
			let parentCompanyId = this.loggedUserCompany.parentCompanyId;
			let filteredObj = Object.assign({}, this.allConnectionsObj);

			_.forEach(this.allConnectionsObj, (con, conId) => {
				if (
					this.filterBy.company.id !== null &&
					this.filterBy.company.id !== con.companyId
				) {
					delete filteredObj[conId];
				} else {
					// filters the data retrieved from snapshot listener
					if (!UserUtil.hasCompanyAccess(this.allCompaniesObj, con.companyId)) {
						delete filteredObj[conId];
					}
					if (parentCompanyId && parentCompanyId.length !== 0 && con.companyId === parentCompanyId) {
						delete filteredObj[conId];
					}
				}

				if (this.filterBy.isActive !== con.isActive) {
					delete filteredObj[conId];
				}
			});

			this.items = Object.values(filteredObj);
			this.items.forEach((item) => {
				item['companyName'] = item.company.name;
				item['connectedCompanyName'] = item.connectedCompany.name;
				item['status'] = item.isActive === 'true' ? 'Active' : 'Inactive';
				item['No of Storage Locations'] = _.size(item.storageLocations);
				item['Storage Locations'] = _.map(item.storageLocations, 'name');
				item['Date Created'] = this.getFormattedDateWithTime(item.dateCreated);
				item['Date Updated'] = this.getFormattedDateWithTime(item.dateUpdated);
			});
			this.totalRows = this.items.length;
		},

		onFilterRequest() {
			if (!_.isEqual(this.filterBy, this.prevFilter)) {
				this.retrieveData();
				this.prevFilter = Object.assign({}, this.filterBy);
			}
		},
		resetFilters() {
			if (!_.isEqual(this.filterBy, this.defaultFilterBy)) {
				// reset to default
				this.filterBy = Object.assign({}, this.defaultFilterBy);
				this.retrieveData();
				this.prevFilter = Object.assign({}, this.filterBy);
			}
		},
		async retrieveData() {
			try {
				// Show loader
				this.isLoading = true;

				let filter = Object.assign({}, this.filterBy);

				if (this.isSuperAdmin) {
					filter.companyId = this.loggedUserCompany.id;
					const { data } = await connectionApi.getConnections(
						filter,
						config.view.ADMIN,
						this.loggedUser.id
					);
					this.allConnectionsObj = _.assign(
						this.allConnectionsObj,
						data.connections
					);
				} else {
					filter.companyId = this.loggedUserCompany.id;
					const { data } = await connectionApi.getConnections(
						filter,
						config.view.COMPANY,
						this.loggedUser.id
					);
					this.allConnectionsObj = _.assign(
						this.allConnectionsObj,
						data.connections
					);
				}

				this.filterTableContent();
			} catch (error) {
				this.$toaster.error(
					'Error loading data. Please reload the page again.'
				);
			}

			// hide loading indicator
			this.isLoading = false;
		},
		updateSelConnection(item) {
			this.selConnection = item;
			this.$store.commit('SET_CURR_CONNECTION', item);
			EventBus.$emit('onUpdateSelConnection', item);
		},
		getIncludedStorageLocations(storageLocations) {
			let activeCount = 0;

			if (!_.isEmpty(storageLocations)) {
				let locArr = Object.values(storageLocations);
				locArr.forEach((loc) => {
					if (loc.isIncluded === 'true') {
						activeCount++;
					}
				});
			}

			return activeCount;
		},
		getFormattedDateWithTime(date) {
			return DateUtil.getFormattedDateWithTime(date);
		},
	},
	beforeDestroy() {
		EventBus.$off('onCloseAddConnection');
		EventBus.$off('onCloseEditConnection');
		EventBus.$off('onCloseActivateConnection');
	},
};
</script>
